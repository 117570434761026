import { graphql } from 'gatsby';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { ListJobsForPageQuery } from '../../graphql-types';
import { TitleSection, TeamBlock, MetaData } from '../components';
import JobCard from '../components/JobCard';
import PersonCard from '../components/PersonCard';
import ThreeCol from '../components/ThreeCol';

type Props = {
	data: ListJobsForPageQuery;
	context?: {
		interest?: string;
	};
	location?: { pathname?: string };
};

const Container = styled.div`
	min-height: 85vh;
`;
const JobsPage = (props: Props) => {
	const jobs = props.data.allWordpressAcfJob.nodes ?? [];

	const title =
		jobs.length > 0 ? `Check out our open positions` : `No jobs up right now`;

	return (
		<React.Fragment>
			<MetaData
				title={'Open positions'}
				description={
					'Take a look through all the open positions available at Town Square'
				}
			/>
			<Container>
				<TitleSection
					padTop
					title={title}
					containerSize="sm"
					color="grey"
					content={
						<Fragment>
							<ThreeCol>
								{jobs.map((job) => (
									<JobCard
										link={`/jobs/${job.id}`}
										id={job.id}
										name={job?.acf?.title ?? ''}
										image={{
											source_url: job.acf?.image?.source_url ?? '',
											alt_text: 'image',
										}}
										role={job.acf?.short_description ?? ''}
										bio={job.acf?.description ?? ''}
									/>
								))}
							</ThreeCol>
						</Fragment>
					}
				/>
			</Container>
		</React.Fragment>
	);
};

export const query = graphql`
	query ListJobsForPage {
		allWordpressAcfJob {
			nodes {
				id
				acf {
					description
					image {
						source_url
					}
					short_description
					title
				}
			}
		}
	}
`;

export default JobsPage;
