import React, { useContext } from 'react';
import styled from 'styled-components';
import Card from '../Card';
import Title from '../Title';
import Context from '../Context';
import ReadMore from '../ReadMore';

type Props = {
	id: string;
	link: string;
	name?: string;
	role?: string;
	image?: {
		source_url?: string;
		alt_text?: string;
	};
	bio?: string;
	onClick?: (id: string) => void;
};

const More = styled(ReadMore)`
	margin-top: ${({ theme }) => theme.spacing[8]};
`;

const Image = styled.div`
	position: relative;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	overflow: hidden;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const Role = styled.p`
	color: ${({ theme }) => theme.colors.primary};
	font-size: ${({ theme }) => theme.fontSize.bodyLg};
`;

const Wrapper = styled(Card)`
	position: relative;
	padding-top: ${({ theme }) => theme.spacing[10]};
	padding-bottom: ${({ theme }) => theme.spacing[10]};

	${Title} {
		margin: ${({ theme }) => `${theme.spacing[7]} 0 0`};
	}

	button {
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&:focus {
			outline: 0;
			color: ${({ theme }) => theme.colors.primary};
		}
	}
`;

const PLACEHOLDER = '/images/logo-square.jpg';

const JobCard: React.FC<Props> = (props) => {
	const handleOnClick = () => {
		props?.onClick?.(props.id ?? '');
	};

	return (
		<Wrapper>
			<Image>
				<img
					src={props?.image?.source_url || PLACEHOLDER}
					alt={props?.image?.alt_text || props.name}
				/>
			</Image>
			<Title as="p" size="sm">
				{props.name}
			</Title>
			{props.role && <Role>{props.role}</Role>}
			{props.bio && <More href={props.link} onClick={handleOnClick} />}
		</Wrapper>
	);
};

export default JobCard;
